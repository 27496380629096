import React from "react";
import AppStoreBadge from "../image/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import "./Projects.css";

const ProjectSample = ({ project }) => {
  return (
    <div className="project-sample-container">
      <div className="project-details">
        <a
          href={project.projectUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="project-link"
        >
          <div className="project-image-container">
            <img
              src={project.imageUrl}
              alt={project.title}
              className="project-image"
            />
            <div className="overlay">
              <div className="overlay-text">View Project</div>
            </div>
          </div>
        </a>
        <div className="details-box">
        <h2 className="project-title">{project.title}</h2>
        {(project.title === "XP1: Your Ultimate Expense Tracking Companion" && (
          <a
            href="https://apps.apple.com/us/app/xp1/id6470176329"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={AppStoreBadge}
              alt="Download on the App Store"
              className="app-store-badge"
            />
          </a>
        )) ||
          (project.title === "Golista!" && (
            <a
              href="https://apps.apple.com/us/app/golista/id6450496916"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={AppStoreBadge}
                alt="Download on the App Store"
                className="app-store-badge"
              />
            </a>
          ))}
        <p className="project-description">{project.description}</p>
        </div>
      </div>


      
      <div className="project-details">
        <div className="details-box">
          <p className="subTitle">Features</p>
          {/* <div className="feature-container"> */}
            {project.features &&
              project.features.map((feature, index) => (
                <p
                  key={index}
                  className="feature"
                  dangerouslySetInnerHTML={{ __html: feature }}
                ></p>
              ))}
        </div>
        <div className="details-box">
          <p className="subTitle">Technical Implementation</p>
          {/* <div className="feature-container"> */}
          {project.technical &&
            project.technical.map((technical, index) => (
              <p
                key={index}
                className="feature"
                dangerouslySetInnerHTML={{ __html: technical }}
              ></p>
            ))}          
          </div>
          {/* </div> */}
        </div>
    </div>
  );
};

export default ProjectSample;
