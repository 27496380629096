import React from "react";
import "./About.css";
import "../project_samples/Projects.css";
import useScreenSize from "../useScreenSize";

const About = ({ image }) => {
  const [awards, mdb] = image;
  const isMobile = useScreenSize("(max-width: 768px)");
  // console.log(isMobile);

  return (
    <div className="Content">
      <div className={isMobile ? "upper-section" : "upper-section-mobile"}>
        <h1 className="tossing">About Me</h1>
        <div className="project-sample-container">
          
            <p className="paragraph">
              Welcome! I am an experienced web and mobile app developer
              specializing in creating engaging, responsive, and user-friendly
              applications. As a full-stack developer, I bring ideas to life by
              managing the entire development process from concept to launch.
              From crafting intuitive user interfaces to ensuring reliable and
              efficient backend systems, I handle every aspect of the
              development journey.
            </p>
            {/* <p className="paragraph">
              My portfolio includes sophisticated financial apps like XP1 and
              vibrant community platforms like Social Dev, showcasing my
              technical excellence and deep understanding of user experience.
              Explore my projects and reach out to discuss how we can elevate
              your business online.
            </p> */}
            <a
              href="https://university.mongodb.com/certification/certificate/470238744"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-container2">
                <img src={mdb} alt="profilePic" />
              </div>
            </a>
          
        </div>
        <div className="project-sample-container">
          
            <p className="paragraph">
              At Dynata Market Research, I specialized in Mobile Device
              Management (MDM) and systems engineering as the project manager
              and application owner for JAMF. I developed and executed a
              comprehensive migration plan for JAMF and CrowdStrike
              applications, significantly improving endpoint security.
            </p>
            <p className="paragraph">
              My responsibilities included creating and managing JAMF policies,
              packages, and configurations for macOS devices, seamlessly
              integrating them with Azure AD and Group Policy Objects (GPOs). I
              also oversaw macOS and Windows desktop imaging processes using
              JAMF and Intune, ensuring smooth and efficient onboarding for new
              employees. Additionally, I leveraged Jira for ticket resolution,
              bug tracking, and optimizing project workflows, showcasing my
              expertise in aligning MDM solutions with comprehensive system
              engineering strategies.
            </p>
            <div className="image-container2">
              <img src={awards} alt="profilePic" />
            </div>
          </div>
        </div>
      </div>
  );
};

export default About;
